<template>
   <div>
       <body>
        <div class="container-scroller">
            <!-- partial:partials/_navbar.html -->
          <Topnav/>
            <!-- partial -->
            <div class="container-fluid page-body-wrapper">
            <!-- Sidebar -->
            <!-- partial -->
            <!-- partial:partials/_sidebar.html -->
         <Sidebar/>
            <!-- partial -->
            <div class="main-panel">
                <div class="content-wrapper">
               <router-view></router-view>
                </div>
                <div class="modal fade" id="exampleModal-4" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-body">
                         <div class="pixel-loader"></div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- main-panel ends -->
            </div>
            <!-- page-body-wrapper ends -->
        </div>
        <!-- container-scroller -->
</body>
   </div>
</template>
<script>
import Sidebar from '@/components/appSidebar.vue'
import Topnav from '@/components/topnav.vue'
 import api from "@/shared/menuApi";
 export default {
    components: {
        Sidebar,
        Topnav,
    },
    data(){
        return{
           id:this.$route.params.id,
        }
    },
    async mounted(){
        if(!localStorage.getItem('token')){
            this.$router.push('/login')
        }
         let response=await api.getAppById(this.$route.params.id);
        if(response.status=="00"){
            let  appData=response.data;
            this.$store.commit('setCurrentApp',appData);
        }
    }
}
</script>

<style>
#closeBtn{
    position:absolute;
    right:10px;
    top:10px;
    z-index:1;
}
.button{
    position:absolute;
    right:10px;
    top:10px;
    z-index:1;
    padding: 60px;
}
select{
    background-color: #2b2e4c;
    color: #fff;
    border:1px solid #424351;
}
#right{
    float: right;
    z-index: 9999;
}
article{
  display: none;
}
article.on{
  display: block;
}
textarea{
    color: #fff;
}
#codeeditor{
    height:500px;
    width:100px;
}
</style>