<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/dashboard/${id}`">
          <i class="fa fa-tachometer menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/design/${id}`">
          <i class="fas fa-bars menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Design Flow </span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/liveview/${id}`">
          <i class="fa fa-braille menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Live View</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/completesession/${id}`">
          <i class="fa fa-braille menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Recent Sessions</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/search/${id}`">
          <i class="fa fa-search menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Search/Download</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/emulator/${id}`">
          <i
            class="fa fa-solid fa-mobile-retro menu-icon"
            style="margin-right: 10px"
          ></i>
          <span class="menu-title">Emulator</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app/actionlogs/${id}`">
          <i class="fas fa-history menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Debug Logs</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="`/app-list`">
          <i class="fas fa-list menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">My Apps</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>
